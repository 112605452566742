.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.mr2{
  margin-right: 2%;
}

.mb2{
  margin-bottom: 2%;
}

.App-link {
  color: #61dafb;
}

.spacebetween{
  justify-content: space-between;
}
.justcenter{
  justify-content: center;
}
.lit1{
  width: 30%;
}
.lit4{
  width: 14%;
}

.duf{
  font-size: 10px;
}

.flex{
  display: flex;
}

.p20{
  padding: 20px !important;
}

.width{
  width: 100%;
}

.p10{
  padding: 10px !important;
}

.width40{
  width: 35%;
}
.topping{
  top: 30%;
  left: 30%;
  position: absolute;
}

.aligncenter{
  align-items: center;
}
.column{
  flex-direction: column;
}
.row{
  flex-direction: row;
}

.mb10{
  margin-bottom: 10% !important;
}

.mb5{
  margin-bottom: 5% !important;
}

.ti{
  text-decoration: none;
  color: inherit;
}
.halfwidth{
  width: 50%;
}

.vertical-center1 {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 33%;
  text-align: center;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 50%;
  text-align: center;
}

.width20{
  width: 20%;
}

.width10{
  width: 10%;
}

/*Strating new designs */
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper, .css-22u6wc .MuiDrawer-paper{
  height: 100% !important;
}
.css-22u6wc .MuiDrawer-paper {
  width: 220px !important;
  box-sizing: border-box;
}
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper, .css-22u6wc .MuiDrawer-paper{
  top: 13vh !important;
  left: 2% !important;
  border-radius: 18px !important;
  background: #141318 !important;
}
.mt2{
  margin-top: 2% !important;
}
.css-49ifcl-MuiPaper-root-MuiAppBar-root, .MuiAppBar-root{
  width: 96.5% !important;
  top: 20px !important;
  left: 23px !important;
  margin-left: 0 !important;
  right: 23px !important;
  border-radius: 18px !important;
  background-color:#141318 !important;
}
body, .css-1jmjcem{
  background-color: #0E0D12 !important;
}
body {
  font-family: 'Poppins', sans-serif !important;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root, .MuiInputLabel-root{
  color:#606060 !important;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root, .MuiOutlinedInput-root{
  color:#606060 !important;
  border: none !important;
  border-radius: 0 !important;
}
.fi1{
  border-radius: 28px !important;
  background: #0E0D12 !important;
  color:#606060 !important;
  height: 31px !important;
  padding: 5px 4px !important;
}
.fi1 input{
  padding: 5px 4px !important;
  height: 0.8em !important;
}
.fi1 label{
  font-size: 0.7rem !important;
  line-height: 0.9em !important;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused, .MuiInputLabel-root.Mui-focused{
  color: #606060 !important;;
}
.icon-noti{
  height: 31px;
  width: 31px;
  top: 51px;
  background: #0E0D12;
  display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.profile1{
  width: 180px !important;
height: 41px !important;
opacity: 0px;
justify-content: center;
align-items: center;
}
.tit{
  margin-right: 79px !important;
  margin-left: 30px !important;
}
.profile-icon1{
  width: 33px !important;
  height: 33px !important;
  border-radius: 28px !important;
  border: 1px solid #358FE5 !important;
  margin-right: 10px;
}
.profile1-icon{
  width: 33px !important;
  height: 33px !important;
  border-radius: 28px !important;
  border: 1px solid #358FE5 !important;
  margin-right: 10px;
}
.profile-text1{
  color: #FFFFFF !important;
  font-weight: 300 !important;
  font-size: 16px !important;
}

.profile1 p{
  color: #FFFFFF !important;
  font-weight: 300 !important;
  font-size: 16px !important;
}
.css-1f2xuhi-MuiDrawer-docked .MuiDrawer-paper{
  background: #141318 !important;
  width: 220px !important;
}
.ti span{
  color: #606060 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 19.2px !important;
}
.mb20{
  margin-bottom: 12%;
}
.others{
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 19.2px !important;
  color: #FFFFFF !important;
  margin-left: 20px !important;
}
.css-cveggr-MuiListItemIcon-root{
  min-width: 36px !important;
}
.mi label{
  color: #FFFFFF !important;
}
.mi{
  border-radius: 8px !important;
  border: 1px solid #333237 !important;
}
.mi input{
  padding: 14.5px 10px !important;
}
.mi .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
  color: #FFFFFF !important;
}
.mi-button{
  background: #9A86E8 !important;
  border-radius: 10px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-transform: none !important;
}
.alignbase{
  align-items: baseline;
}
a{
  text-decoration: none;
  color: inherit;
}
.mi2{
  background: #8E4FF7 !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  padding: 10px !important;
  width: 178px !important;
  height: 38px !important;
  margin-right: 28px !important;
}
.mi3{
  background: #8E4FF7 !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  padding: 10px !important;
  width: 100% !important;
  height: 38px !important;
  margin-right: 28px !important;
}
input.Mui-focused{
  border: none !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:focus{
  border: none !important;
}
.spacearound{
  justify-content: space-around !important;
}
.mbmain{
  margin-top: 2.1%;
}
.mb4{
  margin-bottom: 3%;
}
.inv{
  border-radius: 18px !important;
  background: #141318 !important;
}
.smol{
  margin-right: 3px !important;
}
.mr2{
  margin-right: 2% !important;
}
.inv-header{
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  color: #FFFFFF !important;
  margin-left: 33px !important;
  margin-bottom: 3% !important;
}
.pip p {
  color: #BDBDBD !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
}
/*.dip{
  background: #0E0D12 !important;
}*/
.css-46bh2p-MuiCardContent-root:last-child, .css-1qw96cp:last-child{
  padding-bottom: 8px !important;
}
.css-46bh2p-MuiCardContent-root, .css-1qw96cp{
  padding: 8px !important;
}

.pay{
  background: #FFFFFF !important;
  border-radius: 8px !important;
  color: #0E0D12 !important;
}
.dip{
  border-radius: 12px !important;
  background: #0E0D12 !important;
  color: #BDBDBD !important;
}

.mat1{
  width: 38%;
  height: 255px;
  overflow-x: hidden;
  overflow-y: auto;
}

.wal1{
  width: 71%;
  height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}

.wal2{
  width: 27%;
  height: 290px;
  justify-content: space-between;
}

.pt5{
  padding-top: 5%;
}
.pr5{
  padding-right: 5%;
}
.pl5{
  padding-left: 5%;
}
.pb2{
  padding-bottom: 2%;
}
.wal3{
  width: 100%;
  height: 190px;
}

.wal4{
  width: 100%;
  height: 92px;
}

.ash1{
  font-weight: 400;
  font-size: 27px;
  line-height: 27px;
  color: #606060;
}
.ash{
  font-weight: 400;
  font-size: 27px;
  line-height: 27px;
  color: #606060;
}

.aligncenter{
  align-items: center;
}

.mat2{
  width: 25%;
  height: 250px;
}

.mat3{
  width: 34%;
  height: 250px;
}
.mat3-div{
  height: 48px;
  align-items: center;
  justify-content: center;
  margin-bottom: 1% !important;
}

.p5{
  padding: 5%;
}

.css-1i9yb4d{
  border-radius: 12px !important;
}
.fot label{
  color: #49484B !important;
}
.fot .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
  color: #49484B !important;
}

.fot input, .css-fvipm8,.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
  background: #0D0C11 !important;
  border: 1px solid #262626 !important;
  border-radius: 8px !important;
  color: #BDBDBD !important;
}

.ash{
  color: #D0D0D0 !important;
  margin-left: 2% !important;
}

.cen-header{
  font-size: 30px;
  font-weight: 400;
  line-height: 31px;
  color: #FFFFFF;
  margin-bottom: 3% !important;
}
.mt5{
  margin-top: 5%;
}

.shim{
  font-weight: 300 !important;
  font-size: 28px !important;
  line-height: 29px !important;
  color: #FFFFFF;
}

.css-1b587mz {
  width: 96.5% !important;
  top: 20px !important;
  left: 23px !important;
  margin-left: 0 !important;
  right: 23px !important;
  border-radius: 18px !important;
  background-color: #141318 !important;
}

.tb3{
  height: 54px !important;
  border-radius: 12px !important;
  background-color: #0E0D12 !important;
  width: 50% !important;
  max-width: 50% !important;
  color: #FFFFFF !important;
}

.tb1{
  width: 100%;
}

path{stroke: transparent};

.p10{
  padding: 10%;
}

.css-heg063-MuiTabs-flexContainer, .MuiTabs-flexContainer{
  display: flex !important;
  justify-content: space-between !important;
  width: 100% !important;
}

.css-1aquho2-MuiTabs-indicator, .MuiTabs-indicator{
  background-color: transparent !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected, .MuiTab-root.Mui-selected{
  color: #FFFFFF !important;
  background-color: #8E4FF7 !important;
}

.input-icons {
  width: 60%;
  margin-bottom: 4px;
  margin-right: 20px;
}

.suggestions-card {
  position: absolute;
  top: 100%;
  left: 22%;
  right: 0;
  background: #0E0D12;
  border-radius: 28px;
  z-index: 1000;
  max-height: 476px;
  overflow-y: auto;
  width: 60%;
  color: #505050;
}

.suggestions-card1 {
  position: absolute;
  top: 100%;
  left: 19%;
  right: 0;
  background: #0E0D12 !important;
  border-radius: 28px;
  z-index: 1000;
  max-height: 476px;
  overflow-y: auto;
  width: 80%;
  color: #505050;
}

.width15{
  width: 15% !important;
}

.suggestions-header{
  font-weight: 300;
  font-size: 20px;
  line-height: 19.2px;
  color: #505050;
  margin-left: 0;
  margin-bottom: 1%;
}

.suggestions-header1{
  font-weight: 300;
  font-size: 20px;
  line-height: 19.2px;
  color: #FFFFFF;
  margin-left: 2%;
  margin-bottom: 1%;
}

.lu{
  height: 300px !important;
}

.suggestions-card ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

h1, h2, h3, p, .MuiTypography-root, button, label, input {
  font-family: 'Poppins', sans-serif !important;
}

.suggestion-item {
  padding-top: 2%;
  cursor: pointer;
  font-weight: 300;
  font-size: 20px;
  line-height: 19.2px;
  color: #C8C6C6;
}

.white{
  color: #FFFFFF !important;
}
.mr5{
  margin-right: 5% !important;
}

.mett{
  padding: 0 !important;
}
.fil-head{
  background: #000000;
}
.fil-head p{
  margin: 0;
  font-weight: 400;
  font-size: 24px;
  line-height: 19.2px;
  color: #FFFFFF ;
}

.fil-text{
  color: #A09C9C;
  font-weight: 400;
  font-size: 20px;
  line-height: 19.2px;
}
.p2{
  padding: 2%;
}
.justend{
  justify-content: end !important;
}

.suggestion-item:hover {
  background-color: #f0f0f0; /* Highlight on hover */
}

.input-icons1 {
  width: 45%;
  margin-bottom: 4px;
  margin-right: 20px;
}

.input-icons svg {
  position: absolute;
}
.input-icons button {
  position: absolute;
}


.input-icons1 svg {
  position: absolute;
}

.input-icons svg{
  padding: 0px;
  color: #606060 !important;
  min-width: 50px;
  text-align: center;
  margin-top: 8px;
}

.input-icons button{
  padding: 0px;
  color: #606060 !important;
  min-width: 50px;
  text-align: center;
  margin-top: 14px;
}

.input-icons1 svg{
  padding: 0px;
  color: #606060 !important;
  min-width: 50px;
  text-align: center;
  margin-top: 8px;
}

.pt10{
  padding-top: 10px;
}

input:focus, .input-field:focus{
  border: 0;
}

.input-field2 {
  width: 100%;
  padding: 10px;
  text-align: left;
  padding-left: 40px;
  border-radius: 28px !important;
  background: #0E0D12 !important;
  border: 0 !important;
  color: #606060 !important;
}


.input-field3 {
  width: 100%;
  padding: 10px;
  text-align: left;
  padding-left: 20px;
  border-radius: 15px !important;
  background: #0E0D12 !important;
  border: 0 !important;
  color: #FFFFFF !important;
  height: 100% !important;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
}

.gen4{
  font-size: 20px !important;
  line-height: 30px !important;
  font-weight: 400 !important;
  color: #787878 !important;
}

.gen1{
  height: 58px;
  background: #0E0D12;
  border-radius: 15px;
}

.gen5{
  height: 58px;
  background: #0E0D12;
}

.gen4 .MuiSelect-select{
  font-size: 20px;
}
.gen2 .MuiSelect-root{
  background: #000000;
  border: 0 !important;
  line-height: 16px !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  color: #FFFFFF !important;
  text-align: center;
}

.gen6{
  height: 53px;
  border-radius: 18px;
  background: #8E4FF7;
  width: 100%;
  font-size: 22.5px !important;
  line-height: 24px !important;
  color: #FFFFFF !important;
  padding: 1%;
}

.transparent-bg{
  background: transparent !important;
}

.input-field {
  width: 100%;
  padding: 10px;
  text-align: left;
  padding-left: 40px;
  border-radius: 28px !important;
  background: #0E0D12 !important;
  border: 0 !important;
  color: #606060 !important;
}

.gen{
  left: 60%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    height: 346px;
    width: 432px;
}

.mt3{
  margin-top: 3%;
}

.input-icons2 {
  width: 90%;
  margin-bottom: 4px;
}
.input-icons3 {
  width: 80%;
  margin-bottom: 4px;
}

.input-icons2 svg {
  position: absolute;
  margin-top: 1.8%;
  margin-left: 2%;
}
.input-icons2 button {
  position: absolute;
}

.wi{
  border: 1px solid #313030 !important;
  border-radius: 8px !important;
  padding: 12px 14px !important;
  height: 44px !important;
  width: 83px !important;
  font-weight: 400;
  font-size: 20px;
  line-height: 19.2px;
  color: #A09C9C !important;
}

.wi1{
  border: 1px solid #313030 !important;
  border-radius: 8px !important;
  padding: 12px 14px !important;
  height: 44px !important;
  width: 134px !important;
  font-weight: 400;
  font-size: 20px;
  line-height: 19.2px;
  background: #9A86E8 !important;
}

.wi3{
  border: 1px solid #313030 !important;
  width: 285px !important;
  height: 44px !important;
  padding: 8px 12px !important;
  border-radius: 8px !important;
  background: #141318;
  color: #A09C9C;
}

.log{
  border-radius: 18px !important;
  background: #141318;
}

.ni{
  position: sticky !important;
  top: 0;
  z-index: 10;

}

.tick{
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 36px !important;
  color: #FFFFFF !important;
}

.tick2{
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 27px !important;
  color: #8A8989 !important;
}

.pr{
  border-radius: 8px;
  border: 1px solid #333237 !important;
  height: 43px !important;
}

.mb5{
  margin-bottom: 5%;
}

.mb2{
  margin-bottom: 2%;
}

.mtnone{
  margin-top: 0 !important;
}

.fip{
  position: fixed;
  width: 100%;
  background: #0E0D12;
  height: 80px;
}
.none{
  display: none;
}

.inv1 {
  border-radius: 10px !important;
  background: #141318 !important;
}

.pay3{
  height: 90px;
  border-radius: 15px;
  padding: 15px;
}

.pay3 .rt{
  font-weight: 400;
  line-height: 30px;
  font-size: 20px;
  color: #787878;
  text-align: left;
  margin-bottom: 1.5%;
}
.pay1{
  background: #0E0D12;
  border-radius: 15px;
  height: 21px;
  padding: 20px;
}
.pay1 p{
  font-weight: 500;
  line-height: 36px;
  font-size: 22px;
  color: #FFFFFF;
  margin: 0;
}
.pay1 span{
  font-weight: 500;
  line-height: 20px;
  font-size: 15px;
  color: #FFFFFF;
  align-items: end !important;
  text-transform: uppercase;
}
.alignbase{
  align-items: baseline;
}
.pay6{
  height: 350px;
  border-radius: 17px;
  padding: 15px 20px;
  width: 350px;
}
.pay6 p{
  font-weight: 300;
  color: #787878;
  line-height: 22px;
  font-size: 18px;
  margin-bottom: 1%;
}
.paid{
  height: 100%;
  overflow: auto;
}
.tick{
  border-bottom: 1.87px solid #3B3B3B ;
}
.tick2{
  font-weight: 300 !important;
  color: #FFFFFF !important;
  line-height: 34px !important;
  font-size: 20px !important;
}
.tick3{
  font-weight: 400 !important;
  color: #FFFFFF !important;
  line-height: 23px !important;
  font-size: 17px !important;
  text-align: center;
}
.pay8{
  border: 1px solid #9C9C9C;
  border-radius: 8px;
  padding: 3px;
}
.pay8 p{
  font-weight: 300 !important;
  color: #787878;
  line-height: 15px !important;
  font-size: 12px !important;
}
.trip{
  height: 150px;
  width: 150px;
}
/*@media screen and (max-width: 2450px){
  .css-12i7wg6-MuiPaper-root-MuiDrawer-paper, .css-22u6wc .MuiDrawer-paper {
    background: #141318 !important;
    border-radius: 18px !important;
    left: 2% !important;
    top: 8.3% !important;
}
}

@media screen and (max-width: 2150px){
  .css-12i7wg6-MuiPaper-root-MuiDrawer-paper, .css-22u6wc .MuiDrawer-paper {
    background: #141318 !important;
    border-radius: 18px !important;
    left: 2% !important;
    top: 9.5% !important;
}
}

@media screen and (max-width: 1950px){
  .css-12i7wg6-MuiPaper-root-MuiDrawer-paper, .css-22u6wc .MuiDrawer-paper {
    background: #141318 !important;
    border-radius: 18px !important;
    left: 2% !important;
    top: 9.5% !important;
}
}

@media screen and (max-width: 1850px){
  .css-12i7wg6-MuiPaper-root-MuiDrawer-paper, .css-22u6wc .MuiDrawer-paper {
    background: #141318 !important;
    border-radius: 18px !important;
    left: 2% !important;
    top: 10.5% !important;
}
}

@media screen and (max-width: 1850px){
  .css-12i7wg6-MuiPaper-root-MuiDrawer-paper, .css-22u6wc .MuiDrawer-paper {
    background: #141318 !important;
    border-radius: 18px !important;
    left: 2% !important;
    top: 10.5% !important;
}
}*/


@media screen and (max-width: 1800px){
  /*.css-12i7wg6-MuiPaper-root-MuiDrawer-paper, .css-22u6wc .MuiDrawer-paper {
    background: #141318 !important;
    border-radius: 18px !important;
    left: 2% !important;
    top: 10.5% !important;
}*/
.recharts-responsive-container{
  height: 190px !important;
  width: 190px !important;
}
.recharts-wrapper{
  max-width: 190px !important;
  max-height: 190px !important;
}
.fop{
  padding-bottom: 0 !important;
}
.fop1{
  height: 130px !important;
  width: 130px !important;
}
}

/*@media screen and (max-width: 1630px){
  .css-12i7wg6-MuiPaper-root-MuiDrawer-paper, .css-22u6wc .MuiDrawer-paper {
    background: #141318 !important;
    border-radius: 18px !important;
    left: 2% !important;
    top: 11.5% !important;
}
}

@media screen and (max-width: 1590px){
  .css-12i7wg6-MuiPaper-root-MuiDrawer-paper, .css-22u6wc .MuiDrawer-paper {
    background: #141318 !important;
    border-radius: 18px !important;
    left: 2% !important;
    top: 10.5% !important;

}
}

@media screen and (max-width: 1580px){
  .css-12i7wg6-MuiPaper-root-MuiDrawer-paper, .css-22u6wc .MuiDrawer-paper {
    background: #141318 !important;
    border-radius: 18px !important;
    left: 2% !important;
    top: 11.5% !important;
}
}

@media screen and (max-width: 1495px){
  .css-12i7wg6-MuiPaper-root-MuiDrawer-paper, .css-22u6wc .MuiDrawer-paper {
    background: #141318 !important;
    border-radius: 18px !important;
    left: 2% !important;
    top: 9.5% !important;
}
}

@media screen and (max-width: 1430px){
  .css-12i7wg6-MuiPaper-root-MuiDrawer-paper, .css-22u6wc .MuiDrawer-paper {
    background: #141318 !important;
    border-radius: 18px !important;
    left: 2% !important;
    top: 11.5% !important;
}
}

@media screen and (max-width: 1385px){
  .css-12i7wg6-MuiPaper-root-MuiDrawer-paper, .css-22u6wc .MuiDrawer-paper {
    background: #141318 !important;
    border-radius: 18px !important;
    left: 2% !important;
    top: 9.5% !important
}
}

@media screen and (max-width: 1340px){
  .css-12i7wg6-MuiPaper-root-MuiDrawer-paper, .css-22u6wc .MuiDrawer-paper {
    background: #141318 !important;
    border-radius: 18px !important;
    left: 2% !important;
    top: 11.5% !important;
}
}

@media screen and (max-width: 1305px){
  .css-12i7wg6-MuiPaper-root-MuiDrawer-paper, .css-22u6wc .MuiDrawer-paper {
    background: #141318 !important;
    border-radius: 18px !important;
    left: 2% !important;
    top: 13% !important;
}
}

@media screen and (max-width: 1290px){
  .css-12i7wg6-MuiPaper-root-MuiDrawer-paper, .css-22u6wc .MuiDrawer-paper {
    background: #141318 !important;
    border-radius: 18px !important;
    left: 2% !important;
    top: 13.5% !important;
}
}

@media screen and (max-width: 1280px){
  .css-12i7wg6-MuiPaper-root-MuiDrawer-paper, .css-22u6wc .MuiDrawer-paper {
    background: #141318 !important;
    border-radius: 18px !important;
    left: 2% !important;
    top: 18.5% !important;
}
}

@media screen and (max-width: 1270px){
  .css-12i7wg6-MuiPaper-root-MuiDrawer-paper, .css-22u6wc .MuiDrawer-paper {
    background: #141318 !important;
    border-radius: 18px !important;
    left: 2% !important;
    top: 13.5% !important;
}
}

@media screen and (max-width: 1200px){
  .css-12i7wg6-MuiPaper-root-MuiDrawer-paper, .css-22u6wc .MuiDrawer-paper {
    background: #141318 !important;
    border-radius: 18px !important;
    left: 2% !important;
    top: 13.5% !important;
}
}*/

@media screen and (max-width: 1024px){
  /*.css-12i7wg6-MuiPaper-root-MuiDrawer-paper, .css-22u6wc .MuiDrawer-paper {
    background: #141318 !important;
    border-radius: 18px !important;
    left: 2% !important;
    top: 17% !important;
}*/
.tit{
  margin-right: 75px !important;
  margin-left: 30px !important;
}
.input-icons{
  width: 50% !important;
}
.inv-header {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  color: #FFFFFF !important;
  margin-left: 33px !important;
  margin-bottom: 3% !important;
}
.input-icons1{
  width: 35%;
}
.met .width20{
  width: 30% !important;
}
.recharts-responsive-container{
  height: 150px !important;
  width: 150px !important;
}
.recharts-wrapper{
  max-width: 150px !important;
  max-height: 150px !important;
}
.fop1{
  height: 130px !important;
  width: 130px !important;
}
.fop {
  padding-bottom: 10px !important;
}
.mat2{
  height: 220px !important;
}
.mat3-div{
  flex-wrap: wrap;
}
}

@media screen and (max-width: 900px){
  .input-icons{
    display: none;
  }
  .css-22u6wc{
    display: none;
  }
  .css-1f2xuhi-MuiDrawer-docked{
    display: none;
  }
  .icon-noti{
    display: none;
  }
  .profile1{
    display: none;
  }
  .pot{
    flex-wrap: wrap;
  }
  .mat1{
    width: 100%;
    margin-bottom: 3%;
    height: 455px;
  }
  .mat2{
    width: 100% !important;
    margin-bottom: 3%;
    height: 347px !important;
    justify-content: center;
  }
  .mat3{
    width: 100%;
  }
  .fop{
    display: none !important;
  }
  .recharts-responsive-container{
    height: 290px !important;
    width: 290px !important;
  }
  .recharts-wrapper{
    max-width: 290px !important;
    max-height: 290px !important;
    height: 290px !important;
    width: 290px !important;
  }
  .css-49ifcl-MuiPaper-root-MuiAppBar-root, .MuiAppBar-root{
    width: 91% !important;
  }
  .mat3-div{
    height: 64px !important;
  }
  .ash1, .ash{
    font-weight: 400 !important;
    line-height: 19px !important;
    font-size: 22px !important;
  }
  .input-icons1{
    display: none;
  }
  .met{
    padding: 5% !important;
  }
  .inv-header{
    font-size: 24px !important;
    font-weight: 400 !important;
    line-height: 19px !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
